export const TypologiesEnum = [
  'appartamento',
  'attico',
  'villa',
  'villa-a-schiera',
  'villa-unifamiliare',
  'villa-bifamiliare',
  'villa-trifamiliare',
  'villa-quadrifamiliare',
  'villetta',
  'mansarda',
  'loft',
  'immobile-di-prestigio',
  'stabile-palazzo',
  'casa-indipendente',
  'porzione-di-casa',
  'rustico',
  'casa-colonica',
  'casale',
  'terratetto-terracielo',
  'ufficio',
  'studio',
  'locale-commerciale',
  'capannone',
  'laboratorio',
  'magazzino',
  'deposito',
  'attivita-commerciale',
  'negozio',
  'bar-tabacchi-ricevitoria',
  'generi-monopolio-giochi',
  'ristorante',
  'bar-tavola-calda-fredda',
  'enoteca-wine-bar',
  'pasticceria-gelateria',
  'discoteca-disco-pub-locale-notturno',
  'alimentari-gastronomia',
  'frutta-e-verdura',
  'macelleria-salumeria',
  'panificio-pastificio',
  'pescheria',
  'supermercato-minimarket',
  'carrozzeria-officina-elettrauto',
  'autolavaggio',
  'concessionario',
  'ricambi-e-accessori',
  'garage-autosilos-parcheggio',
  'gommista',
  'audio-video',
  'computer-informatica',
  'sala-giochi',
  'musica-e-strumenti',
  'videoteca-videogames',
  'telefonia-cellulari-elettrodomestici',
  'internet-point-phone-center',
  'hotel',
  'agenzia-viaggi-e-turismo',
  'bed-and-breakfast',
  'articoli-sportivi',
  'abbigliamento-intimo',
  'calzature-pelletteria-valigeria',
  'gioielleria-bijoutteria-orologeria',
  'merceria',
  'centro-estetico-solarium-benessere',
  'profumeria-e-cosmetica',
  'parrucchieri-uomo-donna',
  'palestra-e-impianti-sportivi',
  'articoli-caccia-e-pesca',
  'colorificio-ferramenta-elettricita',
  'edicola-edicola-con-chiosco',
  'farmacia-erboristeria',
  'piante-e-fiori',
  'foto-ottica',
  'giocattoli-gadget',
  'impresa-pulizia',
  'lavanderia-tintoria',
  'cartoleria-libreria',
  'materiali-e-prodotti-edilizi',
  'mobili-arredanento',
  'articoli-da-regalo-casalinghi',
  'idraulica-elettricita',
  'riparazioni-fai-da-te',
  'tempo-libero',
  'agenzia-intermediazioni-immobiliari',
  'agenzia-intermediazioni-creditizie',
  'trullo',
  'dammuso',
  'nuraghe',
  'maso',
  'baglio',
  'rifugio',
  'sasso',
  'baita',
  'agriturismo',
  'residence',
  'multiproprieta',
  'bungalow',
  'terreno-agricolo',
  'terreno-residenziale',
  'terreno-commerciale',
  'box',
  'posto-auto',
  'posto-moto',
  'cascina',
  'show-room',
  'pensione',
  'ostello',
  'relais',
  'hotel-di-lusso',
  'campeggio',
  'stabilimento-balneare',
  'terreno-industriale',
  'terreno-artigianale-terziario',
  'terreno-turistico',
  'stanza-camera',
  'posto-barca',
  'posto-camper-roulotte',
  'auto-silos',
  'castello',
  'dimora-storica',
  'torre',
  'palazzo-storico',
  'villa-storica',
  'convento-monastero',
  'centralina',
  'consorzio-agrario',
  'filiale-bancaria',
  'scuderia',
  'struttura-ricettiva',
  'struttura-commerciale',
  'stabile',
  'palazzo',
  'terratetto',
  'terracielo',
  'bar',
  'tabacchi',
  'ricevitoria',
  'enoteca',
  'wine-bar',
  'pasticceria',
  'gelateri',
  'discoteca',
  'disco-pub',
  'locale-notturno',
  'alimentari',
  'gastronomia',
  'macelleria',
  'salumeria',
  'panificio',
  'pastificio',
  'supermercato',
  'minimarket',
  'carrozzeria',
  'officina',
  'elettrauto',
  'ricambi',
  'accessori',
  'garage',
  'autosilos',
  'parcheggio',
  'computer',
  'informatica',
  'musica',
  'strumenti',
  'videoteca',
  'videogames',
  'telefonia',
  'cellulari',
  'elettrodomestici',
  'internet-point',
  'phone-center',
  'agenzia-viaggi',
  'abbigliamento',
  'intimo',
  'calzature',
  'pelletteria',
  'valigeria',
  'gioielleria',
  'bijoutteria',
  'orologeria',
  'centro-estetico',
  'solarium',
  'benessere',
  'profumeria',
  'parrucchiere',
  'palestra',
  'impianto-sportivo',
  'caccia-e-pesca',
  'colorificio',
  'ferramenta',
  'elettricita',
  'edicola',
  'farmacia',
  'giocattoli',
  'lavanderia',
  'tintoria',
  'cartoleria',
  'Libreria',
  'materiali-edilizi',
  'prodotti-edilizi',
  'mobili',
  'arredamento',
  'articoli-regalo',
  'casalinghi',
  'idraulica',
  'riparazioni',
  'fai-da-te',
  'agenzia-immobiliare',
]

export const TopTypologies = [
  'appartamento',
  'attico',
  'villa',
  'villetta',
  'rustico',
  'casa-indipendente',
]

export const ResidentialTopTypologies = [
  ...TopTypologies,
  'terreno-residenziale',
  'terreno-turistico',
  'box',
  'castello',
]

export const CommercialTopTypologies = [
  'negozio',
  'capannone',
  'ufficio',
  'magazzino',
  'locale-commerciale',
  'stabile-palazzo',
  'terreno-agricolo',
  'terreno-artigianale-terziario',
  'terreno-commerciale',
  'terreno-industriale',
]

export const NewConstructionsTopTypologies = [
  ...TopTypologies,
  'terreno-artigianale-terziario',
  'box',
  'capannone',
  'locale-commerciale',
  'loft',
]

export const SubTypologies = {
  Monolocale: 'monolocale',
  Bilocale: 'bilocale',
  Trilocale: 'trilocale',
  Oltre3Locali: 'oltre3locali',
}

export const SubTypologiesEnum = Object.values(SubTypologies)
