// Routes //////////////////////////////////////////////////////////////////////

export const Property = 'property'
export const PropertyRoutes = { Property }
export const PropertyRoutesEnum = Object.values(PropertyRoutes)

// Types ///////////////////////////////////////////////////////////////////////

export const Deleted = 'deleted'
export const Exists = 'exists'
export const PropertyTypes = { Deleted, Exists }
export const PropertyTypesEnum = Object.values(PropertyTypes)

// Other ///////////////////////////////////////////////////////////////////////

export const PremiumPartner = 'premiumpartner'
export const NetworkAgenti = 'network_agenti'
export const ProprietaUnicredit = 'proprieta_unicredit'

export const PropertyAgentValues = {
  PremiumPartner,
  NetworkAgenti,
  ProprietaUnicredit,
}

export const PropertyAgentValuesEnum = Object.values(PropertyAgentValues)

export function partner(item) {
  const { agent } = item

  if (!agent || !PropertyAgentValuesEnum.includes(agent.type)) {
    return null
  }

  switch (agent.type) {
    case PremiumPartner:
      return {
        name: 'Premium Partner',
        img: '/assets/img/partners/partner-premium-logo.png',
        width: 100,
        height: 24,
        text: `Agente: ${agent.contact.first_name} ${
          agent.contact.last_name
        }<br aria-hidden="true">Mobile: ${
          agent.contact.phone ? agent.contact.phone : 'N.D.'
        }<br aria-hidden="true">Email: ${
          agent.contact.email ? agent.contact.email : 'N.D.'
        }`,
      }

    case NetworkAgenti:
      return {
        name: 'Network Agenti',
        img: '/assets/img/partners/partner-network-logo.png',
        text: 'Immobile proveniente da Network Agenti',
        width: 100,
        height: 36,
      }

    case ProprietaUnicredit:
      return {
        name: 'Proprietà UniCredit',
        img: '/assets/img/partners/partner-unicredit-logo.svg',
        text: 'Immobile di proprietà del Gruppo UniCredit<sup>1</sup>',
        width: 100,
        height: 34,
      }

    default:
      return {}
  }
}

const categoryIconsMap = {
  Residenziale: 'house',
  Commerciale: 'commercial',
  Cantieri: 'enterprise',
  Terreni: 'lands',
}

export const categoryIconsLabelMap = {
  house: 'casa',
  commercial: 'commerciale',
  enterprise: 'cantieri',
  lands: 'terreni',
  prestige: 'prestige',
}

export function iconName(category, isLuxury, agentType) {
  if (isLuxury) {
    return 'prestige'
  }

  if (agentType === PropertyAgentValues.ProprietaUnicredit) {
    return 'proprieta-unicredit'
  }

  return categoryIconsMap[category] ?? 'house'
}
