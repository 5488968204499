export const CookieName = 'claw_uci_enable'
export const CookieAcceptValue = 'Y1'
export const CookieRejectValue = 'NH'
export const CookieExpirationDays = 182

export function parseCookies(cookieHeaders) {
  const cookies = {}
  const splittedCookieHeaders = cookieHeaders.split(';')

  for (const it of splittedCookieHeaders) {
    // eslint-disable-next-line prefer-const
    let [key, ...rest] = it.split('=')

    key = key?.trim()

    if (!key) {
      continue
    }

    const value = rest.join('=').trim()

    if (!value) {
      continue
    }

    const decodedValue = decodeURIComponent(value)

    cookies[key] = decodedValue
  }

  return cookies
}
