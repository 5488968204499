export const VenditaImmobili = 'venditaimmobili'
export const NuoveCostruzioni = 'nuovecostruzioni'
export const ImmobiliCommerciali = 'immobilicommerciali'
export const ImmobiliDiPregio = 'immobilidipregio'
export const ProprietaUnicredit = 'proprieta-unicredit'

export const Sections = {
  VenditaImmobili,
  NuoveCostruzioni,
  ImmobiliCommerciali,
  ImmobiliDiPregio,
  ProprietaUnicredit,
}

export const SectionsEnum = Object.values(Sections)

export const SectionsMap = {
  [VenditaImmobili]: 'residential',
  [NuoveCostruzioni]: 'new-construction',
  [ImmobiliCommerciali]: 'commercial',
  [ImmobiliDiPregio]: 'prestige',
  [ProprietaUnicredit]: 'unicredit',
}
